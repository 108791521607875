document.addEventListener("turbolinks:load", function(event) {
  const file_inputs = [].slice.call(document.getElementsByClassName("file_input"));

  file_inputs.forEach(function (element, index){
    element.addEventListener("change", function(oFREvent){
      var oFReader = new FileReader();
      oFReader.readAsDataURL(element.files[0]);
      oFReader.onload = function (oFREvent) {

        document.getElementById(String(element.id) + "_preview_container").className = String(document.getElementById(String(element.id) + "_preview_container").className).replace(" hidden", "")
        document.getElementById(String(element.id) + "_preview").src = oFREvent.target.result;
      };
    });
  });
})