// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require jquery_ujs

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../css/application.css";

//= require dropzone

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("packs/resizer")
require("packs/alert")
require("packs/sidebar")
require("packs/file_input")
require("packs/accordion")
require("packs/custom_cropper")
require("packs/modal")
require("packs/popup")
require("packs/changelog")
