document.addEventListener("turbolinks:load", function(event) {

    var modal = document.getElementById("modal");
    var current_logo = document.getElementById("current_logo");
    var current_avatar = document.getElementById("current_avatar");
    var tocrop = document.getElementById("tocrop");

    const file_inputs = [].slice.call(document.getElementsByClassName("file_input"));

    file_inputs.forEach(function (element, index){
        element.addEventListener("change", function() {

            if(element.id.includes("avatar")){
                toggleModal(element)
            }
        });
    });

    if (current_avatar != null){
        current_avatar.addEventListener("click", function() {
            toggleModal()
        });
    }
    
    if (current_logo != null){
        current_logo.addEventListener("click", function() {
            toggleModal()
        });
    }

    function toggleModal (element) {
        if (element){
            const [file] = element.files
            if (file) {
                tocrop.onload = function() {
                    if(tocrop.height > tocrop.width){
                        tocrop.style.minHeight = "700px";
                        tocrop.style.maxHeight = "700px";

                    }else{
                        tocrop.style.minWidth = "700px";
                        tocrop.style.maxWidth = "700px";

                    }
                    URL.revokeObjectURL(tocrop.src);
                };
                tocrop.src = URL.createObjectURL(file)
            }
        }

        modal.classList.remove("hidden");

        backbutton = document.getElementById("backbutton");

        backbutton.addEventListener("click", function() {
            modal.classList.add("hidden");
        });
    }
})