document.addEventListener("turbolinks:load", function(event) {
  if (document.getElementById("modal")){
      var x_box = document.getElementById("x_box");
      var y_box = document.getElementById("y_box");
      var size = document.getElementById("size");

      var dragItem = document.getElementById("cropper");
      var container = document.getElementById("crop_container");
      var img = document.getElementById("tocrop");

      var active = false;
      var currentX;
      var currentY;
      var initialX;
      var initialY;
      var xOffset = 0;
      var yOffset = 0;

      container.addEventListener("touchstart", dragStart, false);
      container.addEventListener("touchend", dragEnd, false);
      container.addEventListener("touchmove", drag, false);

      container.addEventListener("mousedown", dragStart, false);
      container.addEventListener("mouseup", dragEnd, false);
      container.addEventListener("mousemove", drag, false);

      size.addEventListener('keyup', function(event) {
        dragItem.style.width = size.value + "px";
        dragItem.style.height = size.value + "px";
      })

      function dragStart(e) {
        if (e.type === "touchstart") {
          initialX = e.touches.clientX - xOffset;
          initialY = e.touches.clientY - yOffset;
        } else {
          initialX = e.clientX - xOffset;
          initialY = e.clientY - yOffset;
        }

        if (e.target === dragItem) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialX = currentX;
        initialY = currentY;

        active = false;
      }

      function drag(e) {
        if (active) {
        
          e.preventDefault();
        
          if (e.type === "touchmove") {
            currentX = e.touches.clientX - initialX;
            currentY = e.touches.clientY - initialY;
          } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          }

          var maxWidth = img.clientWidth - size.value;
          var maxHeight = img.clientHeight - size.value;

          if ( currentX >= maxWidth && currentY < 0){
            currentX = maxWidth;
            currentY = 0;
          } else if ( currentX < 0 && currentY >= maxHeight){
            currentY = maxHeight;
            currentX = 0;
          } else if ( currentY >= maxHeight && currentX >= maxWidth){
            currentY = maxHeight;
            currentX = maxWidth;
          } else if (currentY < 0 && currentX < 0){
            currentX = 0;
            currentY = 0;
          } else if ( currentX >= maxHeight && currentX < 0){
            currentX = maxHeight;
            currentX = 0;
          } else if (currentY < 0) {
            currentY = 0;
          } else if ( currentY >= maxHeight){
            currentY = maxHeight;
          } else if ( currentX >= maxWidth){
            currentX = maxWidth;
          } else if (currentX < 0) {
            currentX = 0;
          }
          
          xOffset = currentX;
          yOffset = currentY;

          setTranslate(currentX, currentY, dragItem);
        }
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";

        x_box.value = xPos;
        y_box.value = yPos;

      }
    }
})

