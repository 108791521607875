document.addEventListener("turbolinks:load", function(event) {
  if(document.getElementById("mobile_nav")) {
    var nav = document.getElementById("mobile_nav");
    var nav_bg = document.getElementById("sidebar_gray_bg");
    var open_nav = document.getElementById("open_nav");
    var close_nav = document.getElementById("sidebar_gray_bg");
    
    nav.className = nav.className + " hide hidden";

    open_nav.addEventListener('click', (event) => {
      nav.className = String(nav.className).replace(" hide", " show");
      nav.className = String(nav.className).replace(" hidden", "");
      if (nav_bg.className.includes(" bg-gray-600 bg-opacity-75") == false) {
        nav_bg.className = nav_bg.className + " bg-gray-600 bg-opacity-75";
      }
    });
    
    close_nav.addEventListener('click', (event) => {
      nav_bg.className = String(nav_bg.className).replace(" bg-gray-600 bg-opacity-75", "");
      nav.className = nav.className + " hide";
    
      setTimeout(function() {
        nav.className = nav.className + " hidden";
      }, 480);
    });
  }
});
