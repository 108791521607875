document.addEventListener("turbolinks:load", function(event) {
  let dropdown = document.getElementsByClassName("changelog_dropdown");
  let dropdown_small = document.getElementsByClassName("changelog_dropdown_small");
  let dropdown_giftcard = document.getElementsByClassName("changelog_dropdown_giftcard");
  let dropdown_small_giftcard = document.getElementsByClassName("changelog_dropdown_small_giftcard");

  for (let i = 0; i < dropdown.length; i++) {
    dropdown[i].addEventListener("click", function(event){
      if (document.getElementById("changelog_changes_" + dropdown[i].id.split('_')[2]).className != "") {
        document.getElementById("changelog_truncate_" + dropdown[i].id.split('_')[2]).className += " hidden";
        document.getElementById("changelog_down_" + dropdown[i].id.split('_')[2]).className += " hidden";
  
        document.getElementById("changelog_changes_" + dropdown[i].id.split('_')[2]).classList.remove("hidden");
        document.getElementById("changelog_up_" + dropdown[i].id.split('_')[2]).classList.remove("hidden");
      }
      else {
        document.getElementById("changelog_changes_" + dropdown[i].id.split('_')[2]).className = String(document.getElementById("changelog_changes_" + dropdown[i].id.split('_')[2]).className) + " hidden";
        document.getElementById("changelog_up_" + dropdown[i].id.split('_')[2]).className += " hidden";
  
        document.getElementById("changelog_truncate_" + dropdown[i].id.split('_')[2]).classList.remove("hidden");
        document.getElementById("changelog_down_" + dropdown[i].id.split('_')[2]).classList.remove("hidden");
      }
    });
  } 
  for (let i = 0; i < dropdown_small.length; i++) {
    dropdown_small[i].addEventListener("click", function(event){
      if (document.getElementById("changelog_changes_small_" + dropdown_small[i].id.split('_')[2]).className != "") {
        document.getElementById("changelog_truncate_small_" + dropdown_small[i].id.split('_')[2]).className += " hidden";
        document.getElementById("changelog_down_small_" + dropdown_small[i].id.split('_')[2]).className += " hidden";
  
        document.getElementById("changelog_changes_small_" + dropdown_small[i].id.split('_')[2]).classList.remove("hidden");
        document.getElementById("changelog_up_small_" + dropdown_small[i].id.split('_')[2]).classList.remove("hidden");
      }
      else {
        document.getElementById("changelog_changes_small_" + dropdown_small[i].id.split('_')[2]).className = String(document.getElementById("changelog_changes_small_" + dropdown_small[i].id.split('_')[2]).className) + " hidden";
        document.getElementById("changelog_up_small_" + dropdown_small[i].id.split('_')[2]).className += " hidden";
  
        document.getElementById("changelog_truncate_small_" + dropdown_small[i].id.split('_')[2]).classList.remove("hidden");
        document.getElementById("changelog_down_small_" + dropdown_small[i].id.split('_')[2]).classList.remove("hidden");
      }
    });
  } 
  for (let i = 0; i < dropdown_giftcard.length; i++) {
    dropdown_giftcard[i].addEventListener("click", function(event){
      if (document.getElementById("changelog_changes_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).className != "") {
        document.getElementById("changelog_truncate_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).className += " hidden";
        document.getElementById("changelog_down_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).className += " hidden";
        
        document.getElementById("changelog_changes_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).classList.remove("hidden");
        document.getElementById("changelog_up_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).classList.remove("hidden");
      }
      else {
        document.getElementById("changelog_changes_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).className = " hidden";
        document.getElementById("changelog_up_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).className += " hidden";
  
        document.getElementById("changelog_truncate_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).classList.remove("hidden");
        document.getElementById("changelog_down_giftcard_" + dropdown_giftcard[i].id.split('_')[3]).classList.remove("hidden");
      }
    });
  } 
  for (let i = 0; i < dropdown_small_giftcard.length; i++) {
    dropdown_small_giftcard[i].addEventListener("click", function(event){
      if (document.getElementById("changelog_changes_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).className != "") {
        document.getElementById("changelog_truncate_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).className += " hidden";
        document.getElementById("changelog_down_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).className += " hidden";
  
        document.getElementById("changelog_changes_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).classList.remove("hidden");
        document.getElementById("changelog_up_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).classList.remove("hidden");
      }
      else {
        document.getElementById("changelog_changes_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).className = String(document.getElementById("changelog_changes_small_" + dropdown_small[i].id.split('_')[3]).className) + " hidden";
        document.getElementById("changelog_up_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).className += " hidden";
  
        document.getElementById("changelog_truncate_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).classList.remove("hidden");
        document.getElementById("changelog_down_small_giftcard_" + dropdown_small_giftcard[i].id.split('_')[3]).classList.remove("hidden");
      }
    });
  } 
});