document.addEventListener("turbolinks:load", function(event) {    
    if (document.getElementById("pop-up")){
        var popup = document.getElementById("pop-up");
        var popup_mobile = document.getElementById("pop-up-mobile");
        var button = document.getElementById("pop-up_button");
        var button_mobile = document.getElementById("pop-up_button_mobile");
        var chevron = document.getElementById("chevron");
        var chevron_mobile = document.getElementById("chevron-mobile");
    
        button.addEventListener("click", togglePopup);
        button_mobile.addEventListener("click", togglePopup);

        function togglePopup(){
            popup_mobile.classList.toggle("opacity-0");
            chevron_mobile.classList.toggle("transform");
            chevron_mobile.classList.toggle("rotate-180");

            if(!popup_mobile.classList.contains("hidden")){
                setTimeout(function() { 
                    popup_mobile.classList.add("hidden");
                }, 500);
            }else{
                popup_mobile.classList.remove("hidden");
            }

            popup.classList.toggle("opacity-0");
            chevron.classList.toggle("transform");
            chevron.classList.toggle("rotate-180");

            if(!popup.classList.contains("hidden")){
                setTimeout(function() { 
                    popup.classList.add("hidden");
                }, 500);
            }else{
                popup.classList.remove("hidden");
            }
        }
    }
})