document.addEventListener("turbolinks:load", function(event) {
  let flash = document.getElementsByClassName("flash")

  if (flash) {
    for (let i = 0; i < flash.length; i++) {
      let flash_item = flash.item(i);
      let time = 4000;
      
      if(flash_item.className.includes("yellow")) {
        time = 6000;
      }
      else if(flash_item.className.includes("red")) {
        time = 10000;
      }

      flash_item.className = flash_item.className + " show";

      hide_flash(flash_item, i, time)
    }
  }
});

function hide_flash(flash_item, i, time) {
  setTimeout(function(){
    flash_item.className = String(flash_item.className).replace(" show", " hide");
    remove_flash(flash_item)
  }, time+(i*1000));
}

function remove_flash(flash_item) {
  setTimeout(function(){
    flash_item.remove();
  }, 200);
}